export const social = {
  accounts: ['instagram', 'facebook', 'pinterest', 'twitter'],
  byId: {
    facebook: {
      name: 'Facebook',
      symbol: 'symbol-facebook',
      link: 'http://www.facebook.com/pages/For-Instants/203965262969473',
    },
    instagram: {
      name: 'Instagram',
      symbol: 'symbol-instagram',
      link: 'https://www.instagram.com/walkandalie/',
      username: 'walkandalie',
      accessToken: '4511828233.84b594d.185ee35e1e8b432ab97181a407563772',
    },
    pinterest: {
      name: 'Pinterest',
      symbol: 'symbol-pinterest',
      link: 'https://www.pinterest.com/walkandalie/',
    },
    twitter: {
      name: 'Twitter',
      symbol: 'symbol-twitter',
      link: 'https://twitter.com/walkandalie',
    },
  },
} as const;

export const subscribe = {
  methods: ['rss', 'feedburner', 'email', 'bloglovin'],
  byId: {
    rss: {
      name: 'RSS',
      symbol: 'symbol-rss',
      link: '/feed',
    },
    email: {
      name: 'Email',
      symbol: 'symbol-mail',
      link: 'http://feedburner.google.com/fb/a/mailverify?uri=ForInstants&loc=en_US',
    },
    feedburner: {
      name: 'FeedBurner',
      symbol: 'symbol-feedburner',
      link: 'http://feeds.feedburner.com/ForInstants',
    },
    bloglovin: {
      name: 'Bloglovin',
      symbol: 'symbol-bloglovin',
      link: 'https://www.bloglovin.com/blogs/for-instants-2652232',
    },
  },
} as const;

export const taxonomies = {
  travel: { name: 'Travel' },
  trips: { name: 'Trips' },
  category: { name: 'Categories', api: 'categories' },
  tags: { name: 'Tags' },
};

export const site = {
  name: 'For Instants',
  domain: 'walkandalie.com',
  protocol: 'https',
} as const;

export const SITE_URL = `${site.protocol}://${site.domain}` as const;

export const styleVars = {
  sidebarBreakpoint: '50rem',
  gutterWidthX: '2rem',
  containerWidthBase: 0.85,
  containerMaxWidth: '87.5rem',
  containerMinWidth: `${50 - 2 * 2}rem`,
  contentWidth: {
    '(min-width: 112.5rem)': '60.5rem', // 87.5rem - 25rem - 2rem
    '(min-width: 102.9rem)': '65.5rem', // 87.5rem - 20rem - 2rem
    '(min-width: 87.5rem)': 'calc(85vw - 20rem - 2rem)',
    '(min-width: 54rem)': 'calc(85vw - 15rem - 2rem)',
    '(min-width: 50rem)': '29rem',
    default: '100vw',
  },
  sidebarSizes: [
    '(min-width: 112.5em) 25rem',
    '(min-width: 87.5em) 20rem',
    '(min-width: 54em) 15rem',
    '(min-width: 50em) calc(100vw - (2rem * 2))',
    '(min-width: 36em) calc((100vw - 6rem) / 2)',
    '100vw',
  ],
} as const;

export const monthNames = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
} as const;

export const imageCredit = {
  'Walker Angell, Jr': {
    '@type': 'Person',
    name: 'Walker Angell',
    givenName: 'Walker',
    familyName: 'Angell',
  },
  'Alison Angell': {
    '@type': 'Person',
    name: 'Alison Angell',
    givenName: 'Alison',
    familyName: 'Angell',
  },
} as const;

/* eslint-disable max-len, global-require, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-var-requires */
export const favicon = {
  link: [
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '57x57',
      href: require('src/images/favicon/apple-touch-icon-57x57.png').default
        .src,
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '114x114',
      href: require('src/images/favicon/apple-touch-icon-114x114.png').default
        .src,
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '72x72',
      href: require('src/images/favicon/apple-touch-icon-72x72.png').default
        .src,
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '144x144',
      href: require('src/images/favicon/apple-touch-icon-144x144.png').default
        .src,
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '60x60',
      href: require('src/images/favicon/apple-touch-icon-60x60.png').default
        .src,
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '120x120',
      href: require('src/images/favicon/apple-touch-icon-120x120.png').default
        .src,
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '76x76',
      href: require('src/images/favicon/apple-touch-icon-76x76.png').default
        .src,
    },
    {
      rel: 'apple-touch-icon-precomposed',
      sizes: '152x152',
      href: require('src/images/favicon/apple-touch-icon-152x152.png').default
        .src,
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: require('src/images/favicon/favicon-196x196.png').default.src,
      sizes: '196x196',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: require('src/images/favicon/favicon-96x96.png').default.src,
      sizes: '96x96',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: require('src/images/favicon/favicon-32x32.png').default.src,
      sizes: '32x32',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: require('src/images/favicon/favicon-16x16.png').default.src,
      sizes: '16x16',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: require('src/images/favicon/favicon-128.png').default.src,
      sizes: '128x128',
    },
  ],
  meta: [
    { name: 'msapplication-TileColor', content: '#F2F2F2' },
    {
      name: 'msapplication-TileImage',
      content: require('src/images/favicon/mstile-144x144.png').default.src,
    },
    {
      name: 'msapplication-square70x70logo',
      content: require('src/images/favicon/mstile-70x70.png').default.src,
    },
    {
      name: 'msapplication-square150x150logo',
      content: require('src/images/favicon/mstile-150x150.png').default.src,
    },
    {
      name: 'msapplication-wide310x150logo',
      content: require('src/images/favicon/mstile-310x150.png').default.src,
    },
    {
      name: 'msapplication-square310x310logo',
      content: require('src/images/favicon/mstile-310x310.png').default.src,
    },
  ],
};
/* eslint-enable max-len, global-require, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-var-requires */
