import React from 'react';
import classNames from 'classnames';

import SearchForm from './SearchForm';

import styles from './index.module.scss';

type Props = {
  isShown?: boolean;
  onToggleClick?: () => void;
};

export default function Search({ isShown = false, onToggleClick }: Props) {
  return (
    <div className={styles.search}>
      <button
        type='button'
        className={classNames(
          'search-toggle',
          { active: isShown },
          styles['search-toggle'],
        )}
        aria-label='Toggle Search'
        onClick={onToggleClick}
      >
        <svg viewBox='0 0 32 32'>
          <use xlinkHref='#symbol-search' />
        </svg>
      </button>

      {isShown && <SearchForm />}
    </div>
  );
}
