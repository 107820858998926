import { ForwardedRef, HTMLProps, forwardRef } from 'react';

type Props = HTMLProps<HTMLInputElement>;

function Input(
  { type = 'text', name, id = `form-input-${name || ''}`, ...rest }: Props,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return <input {...{ ref, type, name, id }} {...rest} />;
}

export default forwardRef(Input);
