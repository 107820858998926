import { FieldReadFunction, InMemoryCacheConfig } from '@apollo/client';
import makePagination from './pagination';

function createToReferenceForByIdField(
  typename: string,
  argName = 'id',
  cacheKeyId = 'id',
): FieldReadFunction {
  return (_, { args, toReference }) => {
    if (!args?.[argName]) return;
    return toReference({
      __typename: typename,
      [cacheKeyId]: args?.[argName] as string,
    });
  };
}

const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        blogComment: {
          read: createToReferenceForByIdField(
            'BlogComment',
            'nodePtrId',
            'nodePtrId',
          ),
        },
        blogComments: makePagination(),
        blogContinentnode: {
          read: createToReferenceForByIdField(
            'BlogContinentnode',
            'nodePtrId',
            'nodePtrId',
          ),
        },
        blogPlace: {
          read: createToReferenceForByIdField(
            'BlogPlace',
            'nodePtrId',
            'nodePtrId',
          ),
        },
        blogPost: {
          read: createToReferenceForByIdField(
            'BlogPost',
            'nodePtrId',
            'nodePtrId',
          ),
        },
        blogTrip: {
          read: createToReferenceForByIdField(
            'BlogTrip',
            'nodePtrId',
            'nodePtrId',
          ),
        },
        blogPosts: makePagination(),
        pagesPage: {
          read: createToReferenceForByIdField(
            'PagesPage',
            'nodePtrId',
            'nodePtrId',
          ),
        },
      },
    },
    BlogComment: {
      keyFields: ['commentPtrId'],
    },
    BlogContinentnode: {
      keyFields: ['nodePtrId'],
    },
    BlogPlace: {
      keyFields: ['nodePtrId'],
    },
    BlogPost: {
      keyFields: ['nodePtrId'],
    },
    BlogTrip: {
      keyFields: ['nodePtrId'],
    },
    PagesPage: {
      keyFields: ['nodePtrId'],
    },
  },
};

export default cacheConfig;
