import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import Nav from './Nav';
import Search from './Search';
import { getSearchStrFromQuery } from './SearchForm';
import HeaderImage from './HeaderImage';

import styles from './index.module.scss';

export default function Header() {
  const { query } = useRouter();
  const searchStr = getSearchStrFromQuery(query);
  const [isNavShown, setIsNavShown] = useState(false);
  const [isSearchShown, setIsSearchShown] = useState(!!searchStr);

  useEffect(() => {
    if (searchStr && !isSearchShown) {
      setIsSearchShown(true);
    }
  }, [searchStr, isSearchShown]);

  return (
    <header id='masthead' className={classNames('container', styles.root)}>
      <div className={styles['nav-bar']} id='nav'>
        <div className='container'>
          <button
            className={classNames(
              'navbar-toggle',
              { active: isNavShown },
              styles['navbar-toggle'],
            )}
            type='button'
            aria-label='Toggle Navigation'
            onClick={() => setIsNavShown(!isNavShown)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='100'
              height='100'
              viewBox='0 0 100 100'
            >
              <path d='M97.5 14.372v7.92c0 1.072-.393 1.997-1.173 2.782-.784.785-1.714 1.177-2.787 1.177H6.46c-1.074 0-2.003-.39-2.784-1.176-.784-.784-1.176-1.71-1.176-2.782v-7.92c0-1.068.392-1.997 1.177-2.78.78-.78 1.71-1.174 2.783-1.174h87.08c1.073 0 2.003.393 2.787 1.173.78.785 1.173 1.713 1.173 2.782zm0 31.67v7.917c0 1.068-.393 1.997-1.173 2.782-.784.785-1.714 1.172-2.787 1.172H6.46c-1.074 0-2.003-.387-2.784-1.172-.784-.785-1.176-1.714-1.176-2.783v-7.92c0-1.068.392-1.997 1.177-2.782.78-.785 1.71-1.177 2.783-1.177h87.08c1.073 0 2.003.394 2.787 1.178.78.784 1.173 1.714 1.173 2.783zm0 31.667v7.913c0 1.073-.393 2.003-1.173 2.783-.784.784-1.714 1.177-2.787 1.177H6.46c-1.074 0-2.003-.393-2.784-1.177-.785-.78-1.177-1.71-1.177-2.783V77.71c0-1.075.39-2 1.176-2.784.78-.784 1.71-1.177 2.783-1.177h87.08c1.073 0 2.003.392 2.787 1.176.78.785 1.173 1.71 1.173 2.783z' />
            </svg>
          </button>

          <HeaderImage isFade={isNavShown || isSearchShown} />

          <Nav isShown={isNavShown} />

          <Search
            isShown={isSearchShown}
            onToggleClick={() => setIsSearchShown(!isSearchShown)}
          />
        </div>
      </div>

      <HeaderImage
        id='header-image'
        isFade={isNavShown || isSearchShown}
        className={styles['header-image']}
      />
    </header>
  );
}
