import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { monthNames } from 'src/config/siteInfo';
import { ArchivePageDocument } from 'src/generated';
import { layoutDataContext } from 'src/utils/LayoutData';
import Link from './Link';

export default function Archives() {
  const { isLoaded } = useContext(layoutDataContext);
  const { data } = useQuery(ArchivePageDocument, { skip: !isLoaded });
  return (
    <ul id='archives-nav' className='submenu'>
      {data?.blogPostArchiveListList?.map((archiveYear) => {
        const { year } = archiveYear;
        if (!year) return null;
        return (
          <Link
            key={year}
            liClassName='year'
            href={`/${year}`}
            prefetch={false}
            submenu={
              <ul className='submenu'>
                {archiveYear.months?.map((month) => (
                  <Link
                    key={month?.month}
                    liClassName='month'
                    href={`/${year}/${String(month?.month).padStart(2, '0')}`}
                    prefetch={false}
                  >
                    {month?.month &&
                      monthNames[month.month as keyof typeof monthNames]}
                  </Link>
                ))}
              </ul>
            }
          >
            {year}
          </Link>
        );
      })}
    </ul>
  );
}
