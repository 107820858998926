import 'isomorphic-fetch';

import { captureMessage } from '@sentry/nextjs';
import type { ErrorOption, FieldPath, FieldValues } from 'react-hook-form';

const IS_SERVER = typeof window === 'undefined';
const IS_PROD = process.env.NODE_ENV === 'production';

const PROTOCOL = 'http';

const PRODUCTION_SUBDOMAIN = IS_SERVER ? 'api-live' : 'api';
const DOMAIN = `${IS_SERVER ? `${PROTOCOL}:` : ''}//${
  IS_PROD ? `${PRODUCTION_SUBDOMAIN}.walkandalie.com` : 'localhost:8000/api'
}`;

const API_BASE = '/v1';

const getFullUrl = (endpoint: string) => {
  const base = DOMAIN + API_BASE;
  return [base, endpoint].join('/');
};

export const getCsrf = () => {
  const value = `; ${document.cookie}`;
  const parts = value.split('; csrftoken=');
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
  return null;
};

export type DjangoError = { message: string; code?: string | null };

export type DjangoErrorResponse = {
  /* eslint-disable @typescript-eslint/naming-convention */
  field_errors: { [fieldName: string]: DjangoError[] };
  non_field_errors: DjangoError[];
  status_code: number;
  /* eslint-enable @typescript-eslint/naming-convention */
};

export function getIsDjangoErrorResponse(
  response: unknown,
): response is DjangoErrorResponse {
  if (!response) return false;
  if (typeof response !== 'object') return false;
  return (
    'field_errors' in response &&
    'non_field_errors' in response &&
    'status_code' in response
  );
}

export class NotOkResponseError extends Error {
  public response: unknown;

  constructor(response: unknown) {
    super();
    this.response = response;
    Object.setPrototypeOf(this, NotOkResponseError.prototype);
  }
}

export function handleFailure<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
>(
  reason: unknown,
  setFieldError: (fieldName: TFieldName, error: ErrorOption) => void,
  setServerError: (errors: DjangoError[]) => void,
) {
  if (reason instanceof NotOkResponseError) {
    if (getIsDjangoErrorResponse(reason.response)) {
      const { field_errors: fieldErrors, non_field_errors: nonFieldErrors } =
        reason.response;

      const erroredFields = Object.keys(fieldErrors);
      erroredFields.forEach((fieldName) => {
        const error = fieldErrors[fieldName];
        if (error.length > 0) {
          setFieldError(fieldName as TFieldName, {
            message: error[0].message,
          });
        }
      });

      const csrfError = nonFieldErrors.find((err) =>
        err.message.startsWith('CSRF Failed'),
      );
      if (csrfError) {
        captureMessage(csrfError.message, { extra: reason.response });
      }

      setServerError(nonFieldErrors);

      if (erroredFields.length > 0 || nonFieldErrors?.length > 0) {
        return;
      }
    }
  }

  setServerError([{ message: 'An unknown error occurred.' }]);
}

function baseApi<Result = unknown>(fullUrl: string, request?: RequestInit) {
  return fetch(fullUrl, request)
    .then((response) =>
      response
        .json()
        .then((json: Result) => ({ json, response }))
        .catch((ex: unknown) => {
          if (ex instanceof Error) {
            throw ex;
          } else if (typeof ex === 'string') {
            throw new Error(ex);
          } else {
            throw Error('Unknown error parsing JSON response!');
          }
        }),
    )
    .then(({ json, response }) => {
      if (!response.ok) {
        throw new NotOkResponseError(json);
      }

      return { json, status: response.status };
    })
    .then(({ json, status }) => ({ response: json, status }));
}

function callApi<Result = unknown>(endpoint: string) {
  const fullUrl = getFullUrl(endpoint);
  return baseApi<Result>(fullUrl, { credentials: 'include' });
}

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

export function sendApi<Result = unknown>(
  endpoint: string,
  method: Method,
  data: unknown,
) {
  const fullUrl = getFullUrl(endpoint);

  const request: RequestInit = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': getCsrf() ?? undefined,
    } as HeadersInit,
    method,
    body: JSON.stringify(data),
  };
  return baseApi<Result>(fullUrl, request);
}

export type PostNewCommentInput = {
  body: string;
  email: string;
  name: string;
  parent?: string | null;
  url?: string;
};

export type PostNewCommentResult = {
  approved: boolean;
  author: { name: string; url: string | null };
  body: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  created_at: string;
  parent: string | null;
  pk: string;
  post: string;
};

export const postNewComment = (postId: string, data: PostNewCommentInput) =>
  sendApi<PostNewCommentResult>(
    `posts/${postId}/comments/?nocache`,
    'POST',
    data,
  );

export type ContactSubmissionInput = {
  name: string;
  email: string;
  subject?: string;
  message: string;
};

export type ContactSubmissionResult = ContactSubmissionInput & {
  pk: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  submitted_on: string;
};

export const contactSubmission = (data: ContactSubmissionInput) =>
  sendApi<ContactSubmissionResult>('contact/', 'POST', data);

export type CurrentUserResult = {
  /* eslint-disable @typescript-eslint/naming-convention */
  pk: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  nickname: string;
  display_as: string;
  gravatar: string;
  /* eslint-enable @typescript-eslint/naming-convention */
};

export const getCurrentUser = () =>
  callApi<CurrentUserResult>('users/__current/');
