import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';

import Form from 'src/components/Forms/Form';
import FormField, { register } from 'src/components/Forms/FormField';
import Input from 'src/components/Forms/Input';

import styles from './index.module.scss';

export function getSearchStrFromQuery(
  query: ParsedUrlQuery | null | undefined,
) {
  if (!query) return;
  const rawParam = query.s;
  if (!rawParam) return;
  if (Array.isArray(rawParam)) return rawParam[0];
  return rawParam;
}

type FormData = {
  s: string;
};

export default function SearchForm() {
  const router = useRouter();

  const formContext = useForm<FormData, unknown, FormData>({
    progressive: true,
    shouldUseNativeValidation: false,
  });

  const searchParam = getSearchStrFromQuery(router.query);

  useEffect(() => {
    formContext.reset({ s: searchParam });
  }, [formContext, searchParam]);

  const handleValidSubmit = useCallback(
    (formData: FormData) => {
      router
        .push(
          {
            pathname: '/',
            query: formData.s ? { s: formData.s.trim() } : undefined,
          },
          undefined,
          { shallow: true },
        )
        // eslint-disable-next-line no-console
        .catch(console.error);
    },
    [router],
  );

  return (
    <Form
      formContext={formContext}
      id='search-form'
      className={styles['search-form']}
      action='/'
      method='get'
      onValidSubmit={handleValidSubmit}
    >
      <FormField
        name='s'
        renderInput={(props) => (
          <Input
            {...register(formContext, props)}
            placeholder='Search'
            autoFocus
          />
        )}
      />
    </Form>
  );
}
