import { useEffect } from 'react';

export default function useFeatureDetection() {
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof CSS !== 'undefined' &&
      typeof CSS.supports !== 'undefined'
    ) {
      const existingClassName = document.documentElement.className;
      if (CSS.supports('object-fit', 'cover')) {
        document.documentElement.className =
          `${existingClassName} has-object-fit`.trim();
      }
    }
  }, []);
}
