import { AppProps } from 'next/app';
import { NextPage } from 'next';
import ga from 'react-ga';
import { ApolloProvider } from '@apollo/client';

import useApolloClient from 'src/services/apollo/client';
import { AuthProvider } from 'src/services/auth';
import useFeatureDetection from 'src/utils/useFeatureDetection';
import Layout from 'src/components/Layout';

import 'normalize.css';
import 'src/styles/global.scss';

const IS_PROD = process.env.NODE_ENV === 'production';

ga.initialize(IS_PROD ? 'UA-19447153-1' : 'UA-XXXXXXXX-X');

type PageProps = { [prop: string]: unknown };

type CustomNextPage = NextPage & {
  getPostPreview: () => React.ReactNode;
  getSidebar: (pageProps: PageProps) => React.ReactNode;
};

type CustomAppProps = Omit<AppProps, 'pageProps'> & {
  Component: CustomNextPage;
  pageProps: PageProps;
};

export default function App({ Component, pageProps }: CustomAppProps) {
  const apolloClient = useApolloClient(pageProps);

  useFeatureDetection();

  return (
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <Layout
          postPreview={Component.getPostPreview?.()}
          sidebar={Component.getSidebar?.(pageProps)}
        >
          <Component {...pageProps} />
        </Layout>
      </AuthProvider>
    </ApolloProvider>
  );
}
