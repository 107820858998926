import { HTMLProps, createContext, useMemo } from 'react';
import {
  FieldValues,
  UseFormHandleSubmit,
  UseFormReturn,
} from 'react-hook-form';

type FormContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> = {
  formContext?: UseFormReturn<TFieldValues, TContext, TTransformedValues>;
  formId?: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FormContext = createContext<FormContextValue>({});

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> = Omit<HTMLProps<HTMLFormElement>, 'onSubmit'> & {
  formContext: UseFormReturn<TFieldValues, TContext, TTransformedValues>;
  onInvalidSubmit?: Parameters<
    UseFormHandleSubmit<TFieldValues, TTransformedValues>
  >[1];
  onValidSubmit: Parameters<
    UseFormHandleSubmit<TFieldValues, TTransformedValues>
  >[0];
};

export default function Form<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>({
  children,
  formContext,
  id: idProp,
  onInvalidSubmit,
  onValidSubmit,
  ...rest
}: Props<TFieldValues, TContext, TTransformedValues>) {
  const contextValue = useMemo<
    FormContextValue<TFieldValues, TContext, TTransformedValues>
  >(() => ({ formContext, formId: idProp }), [formContext, idProp]);

  return (
    <FormContext.Provider value={contextValue as FormContextValue}>
      <form
        {...rest}
        noValidate
        id={idProp}
        onSubmit={formContext.handleSubmit(onValidSubmit, onInvalidSubmit)}
      >
        {children}
      </form>
    </FormContext.Provider>
  );
}
