import { ReactNode } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';

type Props = Omit<NextLinkProps, 'href'> & {
  children?: ReactNode;
  getIsActive?: (pathname: string) => void;
  href: string;
  liClassName?: string;
  submenu?: ReactNode;
};

export default function Link({
  children,
  getIsActive,
  href,
  liClassName,
  submenu,
  ...opts
}: Props) {
  const router = useRouter();
  const isActive = getIsActive
    ? getIsActive(router.asPath)
    : router.asPath.startsWith(href.replace(/\/$/, ''));

  return (
    <li
      aria-current={isActive ? 'page' : undefined}
      className={classNames('menu-item', liClassName)}
    >
      <NextLink href={href} passHref {...opts}>
        {children}
      </NextLink>

      {submenu}
    </li>
  );
}
