import { DocumentNode, useApolloClient } from '@apollo/client';
import { captureException } from '@sentry/nextjs';
import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';

type Result = {
  queries: { document: DocumentNode; data: unknown }[];
};

export function useFetchLayoutData() {
  const client = useApolloClient();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch('/api/layout_data')
      .then(async (result) => {
        const body = (await result.json()) as Result;
        body.queries.forEach(({ document, data }) => {
          client.cache.writeQuery({ query: document, data });
        });
      })
      .catch((reason) => {
        console.error(reason);
        captureException(reason);
      })
      .finally(() => {
        setIsLoaded(true);
        setIsLoading(false);
      });
  }, [client]);

  return { isLoading, isLoaded };
}

type LayoutDataContext = {
  isLoaded: boolean;
  isLoading: boolean;
};

export const layoutDataContext = createContext<LayoutDataContext>({
  isLoaded: false,
  isLoading: false,
});

type Props = {
  children: ReactNode;
};

export function LayoutDataProvider({ children }: Props) {
  const { isLoading, isLoaded } = useFetchLayoutData();
  const ctx = useMemo(() => ({ isLoaded, isLoading }), [isLoaded, isLoading]);
  return (
    <layoutDataContext.Provider value={ctx}>
      {children}
    </layoutDataContext.Provider>
  );
}
