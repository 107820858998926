import { createContext, useState, useEffect, LinkHTMLAttributes } from 'react';
import Head from 'next/head';
import classNames from 'classnames';

import { site, social, favicon, SITE_URL } from 'src/config/siteInfo';
import Symbols from 'src/components/Symbols';
import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import { LayoutDataProvider } from 'src/utils/LayoutData';

import styles from './index.module.scss';

declare global {
  interface Window {
    Typekit: unknown;
    typekitLoaded: () => void;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const TypekitLoadedContext = createContext(
  typeof window !== 'undefined' && !!window.Typekit,
);

type Props = {
  children?: React.ReactNode;
  postPreview?: React.ReactNode;
  sidebar?: React.ReactNode;
};

export default function Layout({ sidebar, postPreview, children }: Props) {
  const [isTypekitLoaded, setIsTypekitLoaded] = useState(
    typeof window !== 'undefined' && !!window.Typekit,
  );

  useEffect(() => {
    window.typekitLoaded = () => setIsTypekitLoaded(true);
  }, []);

  const link: LinkHTMLAttributes<HTMLLinkElement>[] = [
    ...favicon.link,
    { rel: 'profile', href: 'http://gmpg.org/xfn/11' },
  ];
  link.push({ rel: 'pingback', href: `${SITE_URL}/xmlrpc.php` });
  [
    { path: '/feed/', title: 'Feed' },
    { path: '/comments/feed/', title: 'Comments Feed' },
  ].forEach((alt) => {
    link.push({
      rel: 'alternate',
      type: 'application/rss+xml',
      title: `${site.name} &raquo; ${alt.title}`,
      href: `${SITE_URL}${alt.path}`,
    });
  });

  const htmlAttributes: { lang: string; prefix: string; class?: string } = {
    lang: 'en-US',
    prefix: 'og: http://ogp.me/ns# fb: http://ogp.me/ns/fb#',
  };
  if (typeof document !== 'undefined') {
    htmlAttributes.class = `${
      'object-fit' in document.body.style ? 'has' : 'no'
    }-object-fit`;
  }

  return (
    <TypekitLoadedContext.Provider value={isTypekitLoaded}>
      <LayoutDataProvider>
        <div
          id='page'
          className={classNames(
            'hfeed',
            'site',
            {
              'has-sidebar': !!sidebar,
            },
            styles.root,
          )}
        >
          <Head>
            <meta charSet='utf-8' />
            <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
            <meta httpEquiv='x-ua-compatible' content='ie=edge' />
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1'
            />
            <meta name='application-name' content={site.name} />
            <meta
              name='msapplication-notification'
              content={`frequency=30;polling-uri=http://notifications.buildmypinnedsite.com/?feed=${site.protocol}://${site.domain}/feed&amp;id=1;polling-uri2=http://notifications.buildmypinnedsite.com/?feed=${site.protocol}://${site.domain}/feed&amp;id=2;polling-uri3=http://notifications.buildmypinnedsite.com/?feed=${site.protocol}://${site.domain}/feed&amp;id=3;polling-uri4=http://notifications.buildmypinnedsite.com/?feed=${site.protocol}://${site.domain}/feed&amp;id=4;polling-uri5=http://notifications.buildmypinnedsite.com/?feed=${site.protocol}://${site.domain}/feed&amp;id=5;cycle=1`}
            />
            {favicon.meta.map((m) => (
              <meta key={m.name} {...m} />
            ))}
            <meta
              key='og:site_name'
              property='og:site_name'
              content={site.name}
            />
            <meta key='og:locale' property='og:locale' content='en_US' />
            <meta key='og:type' property='og:type' content='website' />
            <meta
              key='article:publisher'
              property='article:publisher'
              content={social.byId.facebook.link}
            />
            <meta
              key='fb:admins'
              property='fb:admins'
              content='100000029184765'
            />
            <meta
              key='fb:admins'
              property='fb:admins'
              content='100010456773016'
            />
            <meta
              key='fb:app_id'
              property='fb:app_id'
              content='1762002964039436'
            />
            {link.map((l) => (
              <link key={l.rel} {...l} />
            ))}
            <script
              type='text/javascript'
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html:
                  'if (!document.getElementsByTagName("html")[0].classList.contains("js")) document.getElementsByTagName("html")[0].className+=" js"',
              }}
            />
            <script
              type='text/javascript'
              src='//use.typekit.net/ivm6zym.js'
              async
              onLoad={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
                'try{Typekit.load({async:true, active: window.typekitLoaded || function(){}});}catch(e){}' as any
              }
            />
            <script
              type='text/javascript'
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: 'try{Typekit.load({async:true});}catch(e){}',
              }}
            />
          </Head>

          <Symbols />

          <Header />

          <div className='container'>
            <div className={styles.wrapper}>
              <div className={styles.row}>
                <div
                  id='content'
                  className={classNames('site-content', styles.content)}
                >
                  <div id='primary' className='content-area'>
                    {children}
                  </div>
                </div>

                {sidebar ? (
                  <div
                    id='sidebar-wrapper'
                    className={styles['sidebar-wrapper']}
                  >
                    {sidebar}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {postPreview}

          <Footer />
        </div>
      </LayoutDataProvider>
    </TypekitLoadedContext.Provider>
  );
}
