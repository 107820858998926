import React, { HTMLAttributes } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';

import logo from 'src/images/NewHeader_filledfont2_cropped@2x.png';

import styles from './index.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
  isFade: boolean;
  sizes?: string;
};

function HeaderImage({
  isFade,
  sizes = '(min-width: 940px) 591px, (min-width: 751px) 70vw, 90vw',
  className,
  ...props
}: Props) {
  return (
    <div
      className={classNames('logo', { fade: isFade }, className, styles.logo)}
      {...props}
    >
      <Link href='/' passHref title='For Instants'>
        <Image alt='' src={logo} sizes={sizes} priority />
      </Link>
    </div>
  );
}

export default HeaderImage;
