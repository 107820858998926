import { FieldFunctionOptions, FieldPolicy, Reference } from '@apollo/client';

type KeyArgs = FieldPolicy<any>['keyArgs'];

type NodesConnection<TNode> = Readonly<{
  totalCount?: number;
  pageInfo?: Readonly<{
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
  }>;
  nodes?: TNode[];
}>;

type Args = {
  offset?: number;
  first?: number;
};

function makeEmptyData<T = Reference>(): NodesConnection<T> {
  return {
    nodes: [],
    pageInfo: {},
  };
}

export default function makePagination<T = Reference>(
  keyArgs: KeyArgs = ['condition', 'filter', 'orderBy'],
): FieldPolicy<
  NodesConnection<T>,
  NodesConnection<T>,
  NodesConnection<T>,
  FieldFunctionOptions<Args>
> {
  return {
    keyArgs,

    read(existing, { args }) {
      if (!existing?.nodes) return;
      const { offset = 0, first = existing.nodes.length } = args || {};
      const nodes = existing.nodes.slice(offset, offset + first);
      return { ...existing, nodes };
    },

    merge(
      // eslint-disable-next-line @typescript-eslint/default-param-last
      existing = makeEmptyData(),
      incoming,
      { args },
    ) {
      const { offset = 0 } = args || {};

      const nodes = existing?.nodes ? existing.nodes.slice(0) : [];

      if (incoming?.nodes) {
        for (let i = 0; i < incoming.nodes.length; i += 1) {
          nodes[offset + i] = incoming.nodes[i];
        }
      }

      const pageInfo: Required<NodesConnection<T>>['pageInfo'] = {
        ...existing.pageInfo,
        ...incoming.pageInfo,
      };

      return {
        ...existing,
        ...incoming,
        nodes,
        pageInfo,
      };
    },
  };
}
