import React from 'react';
import classNames from 'classnames';

export type Props = React.HTMLAttributes<HTMLUListElement> & {
  links: Readonly<string[]>;
  byId: { [slug: string]: { name: string; link: string; symbol: string } };
};

export default function IconLinks({ links, byId, className, ...props }: Props) {
  return (
    <ul className={classNames('links', className)} {...props}>
      {links.map((slug) => {
        const link = byId[slug];
        return (
          <li key={slug} className={`icon-link ${slug}`}>
            <a
              title={link.name}
              href={link.link}
              target='_blank'
              rel='noopener noreferrer'
              aria-label={link.name}
            >
              <svg>
                <use xlinkHref={`#${link.symbol}`} />
              </svg>
            </a>
          </li>
        );
      })}
    </ul>
  );
}
