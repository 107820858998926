import { HTMLProps, ReactElement, ReactNode, useContext } from 'react';
import {
  FieldError,
  FieldPath,
  FieldValues,
  Ref,
  RegisterOptions,
  UseFormReturn,
  get,
  useWatch,
} from 'react-hook-form';
import cx from 'classnames';
import { FormContext } from './Form';

function isInput(ref: Ref): ref is HTMLInputElement {
  return ref instanceof HTMLInputElement;
}

function getErrorMessage(error: FieldError) {
  if (error.message) return error.message;

  if (error.type === 'required') {
    return 'This field is required';
  }

  const input = error.ref && isInput(error.ref) ? error.ref : undefined;

  if (error.type === 'minLength') {
    if (input?.minLength) {
      return `Please enter at least ${input?.minLength} characters`;
    }
    return 'Please enter more characters';
  }

  return 'This field is invalid';
}

type StringLiteral<T> = T extends string
  ? string extends T
    ? never
    : T
  : never;

type RenderInputProps<TFieldName extends string> = {
  className?: string;
  id: string;
  name: TFieldName;
  required: boolean | undefined;
  placeholder: string | undefined;
};

type Props<TFieldName extends string> = HTMLProps<HTMLDivElement> & {
  inputId?: string;
  isRequired?: boolean;
  label?: ReactNode;
  name: StringLiteral<TFieldName>;
  renderInput: (props: RenderInputProps<TFieldName>) => ReactElement;
};

export default function FormField<TFieldName extends string>({
  children,
  className,
  inputId: inputIdProp,
  isRequired,
  label,
  name,
  placeholder = label,
  renderInput,
  ...rest
}: Props<TFieldName>) {
  const { formContext, formId = 'form' } = useContext(FormContext);

  const inputId = inputIdProp ?? `${formId}--field--${name}`;

  const error =
    formContext && (get(formContext.formState.errors, name) as FieldError);

  const value = useWatch({ control: formContext?.control, name });

  return (
    <div
      className={cx('form-group', className, {
        'has-danger': !!error,
        required: isRequired,
        empty: !value,
      })}
      data-form-field={name}
      data-invalid={!!error}
      {...rest}
    >
      {label && (
        <label className='control-label' htmlFor={inputId}>
          {label}
        </label>
      )}

      <div className='control-wrapper'>
        {renderInput({
          className: 'form-control',
          id: inputId,
          name,
          placeholder,
          required: isRequired,
        } as RenderInputProps<TFieldName>)}

        {error && (
          <div className='form-control-feedback feedback help-block'>
            {getErrorMessage(error)}
          </div>
        )}
      </div>

      {children}
    </div>
  );
}

export function register<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  formContext: UseFormReturn<TFieldValues, TContext, TTransformedValues>,
  props: RenderInputProps<TFieldName>,
  options: Omit<RegisterOptions<TFieldValues, TFieldName>, 'required'> = {},
) {
  const { name, required, ...rest } = props;
  return {
    ...formContext.register(name, { ...options, required } as RegisterOptions<
      TFieldValues,
      TFieldName
    >),
    ...rest,
  };
}
