import React from 'react';
import classNames from 'classnames';

import { social } from 'src/config/siteInfo';

// import InstagramImages from 'src/components/InstagramImages';
import SocialLinks from 'src/components/SocialLinks';

import styles from './index.module.scss';

const sizes: string[] = [];
for (let i = 10; i >= 3; i -= 1) {
  sizes.push(`(min-width: ${i * 225 + 1}px) ${(1 / (i + 1)) * 100 * 1.5}vw`);
}
sizes.push(`${(1 / 3) * 100 * 1.5}vw`);

export default function Footer() {
  return (
    <footer
      id='colophon'
      className={classNames('site-footer', styles.root)}
      role='contentinfo'
    >
      <div className={styles.instagram}>
        <a
          href={social.byId.instagram.link}
          className={styles['account-link']}
          target='_blank'
          rel='noopener noreferrer'
        >
          @{social.byId.instagram.username}
        </a>
        {/* <InstagramImages
          start={9}
          imageSizes={sizes.join(', ')}
          styleName='instagram-images'
        /> */}
      </div>

      <div className={styles['footer-content']}>
        <div className='container'>
          <div className={styles['site-info']}>
            Copyright &copy;{new Date().getFullYear()} Walk &amp; Alie. All
            rights reserved.
          </div>

          <div
            id='social'
            className={classNames('menu-social-links-container', styles.social)}
          >
            <SocialLinks className={styles.links} />
          </div>
        </div>
      </div>
    </footer>
  );
}
