import React from 'react';
import classNames from 'classnames';

import Link from './Link';
import Archives from './Archives';

import styles from './index.module.scss';

type Props = {
  isShown?: boolean;
};

export default function Nav({ isShown }: Props) {
  return (
    <nav
      id='primary-nav'
      className={classNames(
        'menu-primary-nav-container',
        { show: isShown },
        styles.root,
      )}
    >
      <ul id='menu-primary-nav' className={classNames('menu', styles.menu)}>
        <Link href='/about/'>About</Link>

        <Link href='/travel/'>Destinations</Link>

        <Link
          href='/archive/'
          submenu={<Archives />}
          getIsActive={(p) => /\/\d{4}\/?/.test(p)}
        >
          Archive
        </Link>

        <Link href='/contact/'>Contact</Link>
      </ul>
    </nav>
  );
}
