// Mostly inspired by
// https://github.com/vercel/next.js/blob/b3f2b388443a7b94dddaa112665892816f144542/examples/with-apollo/lib/apolloClient.js

import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import merge from 'deepmerge';
import { isEqual } from 'lodash';
import cacheConfig from './cacheConfig';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

type ApolloPageProps = {
  [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject;
  [prop: string]: unknown;
};

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createApolloClient(mainApolloLink?: ApolloLink) {
  const isServer = typeof window === 'undefined';
  return new ApolloClient({
    ssrMode: isServer,
    link: mainApolloLink ?? new HttpLink({ uri: '/api/graphql' }),
    cache: new InMemoryCache(cacheConfig),
  });
}

export function initializeApollo(
  initialState: NormalizedCacheObject | null = null,
  mainApolloLink?: ApolloLink,
) {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
  const _apolloClient: ApolloClient<NormalizedCacheObject> =
    apolloClient ?? createApolloClient(mainApolloLink);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray: unknown[], sourceArray: unknown[]) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: { props: ApolloPageProps },
) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export default function useApolloClient(pageProps: ApolloPageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
