import {
  ReactElement,
  createContext,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { differenceInMinutes } from 'date-fns';
import {
  CurrentUserResult,
  NotOkResponseError,
  getCurrentUser,
  getIsDjangoErrorResponse,
} from './api';

type AuthContextValue = {
  checkAuth: () => Promise<CurrentUserResult | null>;
  currentUser?: CurrentUserResult | null;
  isLoggedIn?: boolean;
  isLoading: boolean;
};

export const authContext = createContext<AuthContextValue>({
  isLoading: false,
  checkAuth: () => Promise.resolve(null),
});

type Props = {
  children: ReactElement;
};

export function AuthProvider({ children }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState<
    CurrentUserResult | null | undefined
  >();

  const lastCheck = useRef<Date>();

  const checkAuth = useCallback(async () => {
    const csrfCookie = document.cookie
      .split('; ')
      .find((c) => c.startsWith('csrftoken'));

    if (!csrfCookie) return null;

    if (
      currentUser &&
      lastCheck.current &&
      differenceInMinutes(new Date(), lastCheck.current) < 60
    ) {
      return null;
    }

    setIsLoading(true);

    try {
      const result = await getCurrentUser();

      setCurrentUser(result.response);
      lastCheck.current = new Date();

      return result.response;
    } catch (e) {
      if (e instanceof NotOkResponseError) {
        const { response } = e;
        if (
          getIsDjangoErrorResponse(response) &&
          (response.status_code === 401 || response.status_code === 403)
        ) {
          return null;
        }
      }
      throw e;
    } finally {
      setIsLoading(false);
    }
  }, [currentUser]);

  const value = useMemo<AuthContextValue>(
    () => ({ isLoading, currentUser, isLoggedIn: !!currentUser, checkAuth }),
    [isLoading, currentUser, checkAuth],
  );

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
}
