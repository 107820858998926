import React from 'react';
import classNames from 'classnames';

import { social } from 'src/config/siteInfo';

import IconLinks, { Props } from 'src/components/IconLinks';

export default function SocialLinks({
  className,
  ...props
}: Omit<Props, 'links' | 'byId'>) {
  return (
    <IconLinks
      className={classNames('social-links', className)}
      links={social.accounts}
      byId={social.byId}
      {...props}
    />
  );
}
